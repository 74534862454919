<script setup lang="ts">
import { onMounted, computed, ref, watch, type Ref, type Events } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useOffersStore } from '~/stores/account/offers.store';
import { useListsStore } from '~/stores/account/lists.store';
import { useLayoutStore } from '~/stores/layout.store';
import CitiesInput from './cities-input.vue'
import HasRole from '~/components/account/has-role.vue';

const route = useRoute();
const router = useRouter()
const offersStore = useOffersStore();
const layoutStore = useLayoutStore();
const listsStore = useListsStore();
const { $toast } = useNuxtApp()
const localePath = useLocalePath()
const id = ref(route.params.id as string | null);
const isLoading = ref(true);
const isAddLoading = ref(false)

const form: Ref<any> = ref({
	id: null,
	customer_id: null,
	category_name: null,
	category_id: null,
	city_name: null,
	progress: null,
	created_At: null,
	city_id: null,
	country_id: null,
	category: null,
	min_price: null,
	max_price: null,
	currency_id: 1,
	feature_id: {},
});

onMounted(async () => {
	listsStore.fetchFlatCategories()
	listsStore.fetchCustomers()
	if (id.value) {
		form.value = await offersStore.fetchOfferById(id.value);
		isLoading.value = false
	}
});

watch(
	() => form.value.category_id,
	async (current_id: number, prev_id: number) => {
		offersStore.fetchOffersForm(current_id)
	}
);

const progresses = [
	{ id: 0, key: 'enquiry', value: 'Enquiry' },
	{ id: 1, key: 'agent_reply', value: 'Agent Reply' },
	{ id: 2, key: 'customer_reply', value: 'Customer Reply' },
	{ id: 3, key: 'meeting', value: 'Meeting' },
	{ id: 4, key: 'sale', value: 'Sale' },
];

const flatCategories = computed(() => listsStore.flatCategoriesGetter())
const customersList = computed(() => listsStore.customersGetter()) 
const formFields = computed(() => offersStore.formGetter());
const currencies = computed(() => layoutStore.currenciesGetters())

const setDynamicFeature = (feature_id: number, event: any) => {
	const value = event.target.value
	form.value.feature_id[feature_id] = value
}

const handleCityIdUpdate = (city_id: number) => {
	form.value.city_id = city_id
}

const addOffer = async () => {
	await offersStore.addOffer(form.value)
		.then((res: any) => {
			$toast.addToast({
                title: res.data.message || "Offer Recorded Successfully",
                timeout: 5000,
                position: 'top-10',
			})
			router.push({ path: localePath({ name: 'account.offers.index' }) });
		}) 
		.catch((err) => {
			const message = err?.message || 'Something Went Wrong Please Try Again'
            $toast.addToast({ title: message, color: "red", position: 'bottom-10' })
		})
}

const updateOffer = async () => {
	await offersStore.updateOffer(id.value!, form.value)
		.then((res: any) => {
			
			$toast.addToast({
                title: res.data.message || "Offer Updated Successfully",
                timeout: 5000,
                position: 'top-10',
			})
			router.push({ path: localePath({ name: 'account.offers.index' }) });
		}) 
		.catch((err) => {
			const message = err?.message || 'Something Went Wrong Please Try Again'
            $toast.addToast({ title: message, color: "red", position: 'bottom-10' })
		})
}

const offerAction = async () => {
	isAddLoading.value = true
	try 
	{
		if (id.value) 
		{
			await updateOffer();
		}
		else 
		{
			await addOffer();
		}
	} 
	finally 
	{
		isAddLoading.value = false;
	}
}

</script>	
<template>
	<NuxtLayout name="mobile-base">
		<template #mobile-header>
			<mobile-header :title="id ? $t('client.edit_offer') : $t('client.add_offer')">
				<template #header-left>
					<mobile-back-button></mobile-back-button>
				</template>
			</mobile-header>
		</template>
		<template v-if="isLoading && id">
			<div class="relative h-screen">
				<div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
					<span class="loader"></span>
				</div>
			</div>
		</template>
		<div v-else class="filter p-4">
			<div class="pb-3">
				<div class="mt-3">
					<input 
						type="number" 
						:placeholder="$t('client.min_price')"
						class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
						v-model="form.min_price"
					>
				</div>
				<div class="mt-3">
					<input 
						type="number" 
						:placeholder="$t('client.max_price')"
						class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
						v-model="form.max_price"
					>
				</div>
				<div class="mt-3">
					<h3 class="text-base text-indigo-900 leading-normal  mb-1">{{ $t('client.currency') }}</h3>
					<select
						id="currency"
						class="bg-white block w-full ring-1 ring-gray-300 ring-inset border shadow-sm text-gray-900 text-base h-10 rounded-md focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
						v-model="form.currency_id"
					>
						<option 
							v-for="(currency, i) in currencies" 
							:key="i"
							:value="currency._id"
						>
							{{ currency['symbol'] }}
						</option>
					</select>
				</div>
				<div class="mt-3">
					<h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.category') }}</h3>
					<select 
						id="categories"
						class="bg-white block w-full  ring-1 ring-gray-300 ring-inset border shadow-sm text-gray-900 text-base h-10 rounded-md focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
						v-model="form.category_id"
						:placeholder="$t('client.category')"
					>
						<option 
							v-for="category in flatCategories"
							:key="category.id"
							:value="category.id"
						>
							{{ category.name }}
						</option>
					</select>
				</div>
				<div class="mt-3">
					<h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.customer') }}</h3>
					<select 
						id="customers"
						class="bg-white block w-full  ring-1 ring-gray-300 ring-inset border shadow-sm text-gray-900 text-base h-10 rounded-md focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
						v-model="form.customer_id"
						:placeholder="$t('client.customer')"
					>
						<option 
							v-for="customer in customersList"
							:key="customer.id"
							:value="customer.id"
						>
							{{ customer.full_name }}
						</option>
					</select>
				</div>
				<div class="mt-3">
					<CitiesInput @update:city="handleCityIdUpdate" />
				</div>
				<div class="mt-3">
					<h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.progress') }}</h3>
					<select 
						id="progress"
						class="bg-white block w-full  ring-1 ring-gray-300 ring-inset border shadow-sm text-gray-900 text-base h-10 rounded-md focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
						v-model="form.progress"
						:placeholder="$t('client.progress')"
					>
						<option
							v-for="progress in progresses"
							:key="progress.id"
							:value="progress.key"
						>
							{{ progress.value }}
						</option>
					</select>
				</div>

				<div 
					v-for="field in formFields"
				>
					<div v-for="feature in field.features">
						<h3 class="text-base text-indigo-900 leading-normal mb-1">{{ feature.feature_name }}</h3>
						<select 
							id="progress"
							class="bg-white block w-full  ring-1 ring-gray-300 ring-inset border shadow-sm text-gray-900 text-base h-10 rounded-md focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
							:placeholder="$t('client.progress')"
							@change="setDynamicFeature(feature.feature_id, $event)"
						>
							<option
								v-for="source in feature.data_source"
								:key="source.id"
								:value="source.id"
							>
								{{ source.name }}
							</option>
						</select>
					</div>
				</div>

			</div>
		</div>
		<template #mobile-footer>
			<div @click="offerAction" class="fixed bottom-0 bg-white p-4 w-full">
				<div class="flex items-center gap-3">
					<div class="bg-indigo-900 text-white rounded-lg p-3 capitalize w-full text-center">
						<span v-if="isAddLoading">
							<span class="loader me-3"></span>
						</span>
						<span v-else>
							{{ id ? $t('client.update') : $t('client.add') }}
						</span>
					</div>
				</div>
			</div>
		</template>
	</NuxtLayout>
</template>
